<template>

  <footer class="footer">
    <el-link :underline="false" href="https://beian.miit.gov.cn" target="_blank">浙ICP备2022028353号-1</el-link> 

<el-link target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020902000510" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../assets/img/beian.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33020902000510号</p></el-link>
    <span class="copyright">Licensed 优宜真空 | Copyright © 2019-{{ currentYear }} YOIVAC</span> 
  </footer>

</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-top: 1px solid #e6e6e6;
  background-color: #ffffff;
  width: 100%;
}

.copyright{
  color: #242323;
  padding-left:20px;
  font-size: 15px;
}
</style>