<template>
  <div>
    <el-row>
      <el-col :span="6">
        <img :src="$store.state.baseurl+'/Images/'+ (companyData.CompanyLogo || '').split(' ')[0]" alt="优宜真空" />
      </el-col>
      <el-col :span="12">
        <el-menu mode="horizontal" text-color="#000000" :default-active="$route.path" active-text-color="#FFA500" router>
          <el-menu-item index="/home">
            <i class="el-icon-s-platform"></i>{{$t('home.home')}}
          </el-menu-item>
          <el-menu-item index="/products/3">
            <i class="el-icon-s-grid"></i>{{$t('home.products')}}
          </el-menu-item>
         
        </el-menu>
      </el-col>
      <el-col :span="6" class="lang">
        <el-button type="text" @click="changetochn">中文</el-button>  <el-button type="text" @click="changetoeng">English</el-button>
      </el-col>
    </el-row>
    <div>
      <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in crouselpics" :key="item">
          <img :src="item" width="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavHeader',
  data() {
    return {
      crouselpics: [
       
      ],
      companyData:{}
    }
  },
  created(){
    this.$http.get('/company/getcompany').then(res=>{
      this.companyData=res.data.data

      var list = (res.data.data.CarouselPics || '').split(' ')
        list.forEach(element => {
          this.crouselpics.push(this.$store.state.baseurl + '/Images/' + element)
        })
      });
    
  },
  methods:{
    changetochn(){
      localStorage.setItem('user-language', 'zh-cn')
      this.$i18n.locale = 'zh-cn'
    },
    changetoeng(){
      localStorage.setItem('user-language', 'en')
      this.$i18n.locale = 'en'
    }
  }
}
</script>

<style scoped>
.el-menu--horizontal > .el-menu-item {
  float: right;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.lang{
  display: flex;
  flex-direction: row;
  justify-content:flex-end;
  height: 100%;
}

</style>
