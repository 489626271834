<template>
  <div>
    <NavHeader />

      <router-view />
      <Bottom />>
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue'
import Bottom from '@/components/Bottom.vue'


export default {
  components: {
    NavHeader,
    Bottom
  }
}
</script>